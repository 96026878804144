.outView{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.imageView{
    border-radius: 4px;
    object-fit: cover;
    width: 100%;
}
.textView{
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    /* or 175% */

    text-align: justify;

    color: #333333;

}
