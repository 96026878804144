.titleText{
    margin-bottom: 30px;
}

.outView{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    box-sizing: border-box;
}

.formGroup{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    box-sizing: border-box;
    width: 300px;
}

.formItem{
    border: none;
    box-sizing: border-box;
    margin: 10px 0;
    border-bottom: solid 1px #bbafaf;
    font-size: 20px;
    outline: none;
    width: 100%;
}

.TextWelcome{
    margin-right: 10px;
    font-style: normal;
    font-weight: 400;
    font-size: 26px;
    line-height:40px;
    /* identical to box height, or 222% */
    color: #333333;
}

.TextName{
    font-style: normal;
    font-weight: 800;
    font-size: 26px;
    line-height: 40px;
    /* identical to box height, or 222% */
    color: #333333;
}

.validCodeFormItems{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    box-sizing: border-box;
}

.changeLoginTypeText{
    cursor: pointer;
    color: gray;
    font-size: 10px;
}
.changeLoginTypeText:hover{
    cursor: pointer;
    color: #6fa168;
    font-size: 10px;
}

