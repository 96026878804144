.outView{
    width: 100%;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.labs{
    width: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.caseItemContent{
    width: 1200px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.caseItemGroup{
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 18px;
    padding: 20px 0;
}

.labsOut{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}

.newCaseBtn{
    cursor: pointer;
    background: #F13B3B;
    border-radius: 4px;
    box-sizing: border-box;
    padding: 10px 20px;
    flex-grow: 0;
    flex-shrink: 0;

    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    /* or 133% */


    color: #FFFFFF;
}
