.outView{
    width: 600px;
    height: 377px;
    display: flex;
    flex-direction: column;
    justify-self: center;
    align-items: flex-start;
}

.closeIcon{
    cursor: pointer;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    font-size: 30px;
}


.baoJiaView{
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 39px;
    color: #111111;

    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.tipText{
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: #666666;
    padding-top: 30px;
    padding-bottom: 15px;
}

.input{
    width: 100%;
    height: 60px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    font-size: 30px;
}

.input::placeholder {
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: #999999;
    padding: 20px 0;
}

.inputOutView{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-self: center;
    align-items: center;
}

.btn{
    cursor: pointer;

    width: 100%;
    height: 60px;
    background: #FC3349;
    border-radius: 10px;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    box-sizing: border-box;

    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 34px;
    /* identical to box height */


    color: #FFFFFF;
}

.btnOut{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-self: center;
    align-items: center;
    box-sizing: border-box;
    padding: 10px 0;
}
