.outView {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    min-height: 500px;
    min-width: 800px;
}

.contentView {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    height: 100%;
}

.contentInfo {
    background-color: #F85B6C;
    height: 100%;
    width: 40%;
}

.contentForm {
    background-color: #fff;
    width: 60%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding-left: 5%;
    box-sizing: border-box;
}
