.baseWidth {
    width: 1200px;
}

.circleProfile_big {
    border-radius: 50%;
    width: 30px;
}

.toCenter{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
}

.toLeft{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    box-sizing: border-box;
}
