.outView {
    cursor: pointer;
    width: 681px;
    height: 383px;
    overflow: hidden;
}
.imgView{
    width: 100%;
    height: 100%;
    overflow: hidden;
    object-fit: cover;
}

.swiperText{
    position: relative;
    bottom: 50px;
    height: 50px;
    background: rgba(0, 0, 0, 0.4);

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: #FFFFFF;
    padding-left: 20px;
}
