.currentItem{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    background: #FC3349;
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 34px;
    color: #FFFFFF;
    flex-grow: 0;
    flex-shrink: 0;
    cursor: pointer;
    margin: 4px;
}

.notCurrentItem{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    background: #F6F6F6;
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 34px;
    color: #333333;
    flex-grow: 0;
    flex-shrink: 0;
    cursor: pointer;
    margin: 4px;
}


.outView{
    width: 40%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 70px;
}

.numGroup{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
