.outView {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    box-sizing: border-box;
    padding: 10px;
    background: #F8F8F8;
    min-width: 250px;
}
.title{
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: #333333;
    padding-bottom: 10px;
    width: 100%;
    text-align: left;
}

.itemOutView{
    cursor: pointer;
    width: 100%;
    padding: 5px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    box-sizing: border-box;
}

.itemTitle{
    width: 100%;
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #333333;
    box-sizing: border-box;
}
.itemAddress{
    width: 100%;
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: left;
    color: #666666;
    padding-top: 3px;
    box-sizing: border-box;
}

.itemTitleAddressGroup{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 5px;
    box-sizing: border-box;
    text-align: left;
}

.nodeListView{
    width: 100%;
    height: 100%;
}
