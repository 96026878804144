.wantPriceItemOutView{
    width: 100%;
    box-sizing: border-box;
    padding: 10px;

    display: grid;
    grid-template-columns: 130px auto 200px;

}

.line{
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.08);
}

.outView{
    width: 100%;
}

.wantPriceLineGroup{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.imgOutView{
    cursor: pointer;
    width: 120px;
    height: 120px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.img{
    width: 110px;
    height: 110px;
    border-radius: 4px;
}

.titleGroup{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}
.title{
    cursor: pointer;
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    text-align: justify;
    color: #111111;
}
.address{
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    text-align: justify;
    color: #666666;
    padding: 5px 0;
}

.mobile{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    box-sizing: border-box;
    padding: 10px 0;
}

.createAtGroup{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    flex-grow: 0;
    flex-shrink: 0;


    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height, or 138% */

    text-align: justify;

    color: #999999;

}
