.outView {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.titleArea {
    height: 68px;
    width: 100%;
    background-color: #F13B3B;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.contentArea {
    width: 1200px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.titleContent {
    width: 1200px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.titleText {
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    color: #FFFFFF;
}

.checkInText {
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 34px;
    color: #111111;
    padding: 20px;
}

.formArea {
    width: 1200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.formButtons {
    margin-top: 30px;
    margin-bottom: 30px;
    width: 600px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}


.formGroup{
    width: 100%;
    display: grid;
    grid-template-columns: 250px auto;
    padding: 10px;
    box-sizing: border-box;
}

.formLabel {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.formContent {
    width: 500px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-left: 20px;
    border: 1px solid rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #999999;
    padding: 10px 20px;
}

.formContentSelect{
    margin-left: 20px;
    width: 500px;
}
.formContentSelectPrefix{
    width: 500px;
}





