.overlayClass {
    position: fixed;
    inset: 0px;
    background-color: rgba(255, 255, 255, 0.75);
}

.contentClass {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 30%;
    margin: auto;

    width: fit-content;
    height: fit-content;
    border: 1px solid rgb(204, 204, 204);
    border-radius: 4px;
    background: rgb(255, 255, 255);
    overflow: auto;
    outline: none;
    padding: 20px;
}
