.outView{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    border: 1px solid rgba(0, 0, 0, 0.08);
    border-radius: 4px;
}
.coverView{
    cursor: pointer;
    width: 100%;
    height: 218px;
    overflow: hidden;
    object-fit: cover;
}
.titleView{
    cursor: pointer;
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    line-height: 24px;
    color: #333333;
    box-sizing: border-box;
    padding: 20px 20px;
}

.priceView{
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 40px;
    color: #FC3349;
    box-sizing: border-box;
    padding: 0 20px;
}

.businessInfoView{
    display: grid;
    grid-template-columns: 60px auto;
    padding: 10px 20px;
}

.userInfoProfile{
    border-radius: 50%;
    width: 46px;
    height: 46px;
}

.companyInfoView{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    box-sizing: border-box;
    padding: 5px 10px;
}

.userInfoProfileView{
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.companyNameText{
    cursor: pointer;
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #333333;

}
.companyAddressText{
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #666666;
}
