.outView{
    width: 209px;
    height: 383px;
    cursor: pointer;
}

.img{
    width: 209px;
    height: 383px;
    overflow: hidden;
    object-fit: fill;
}

.adText{
    position: relative;
    right: -168px;
    top: -384px;
    height: 30px;
    background: rgba(0, 0, 0, 0.4);
    color: white;
    width: 40px;
}
