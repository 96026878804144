.whiteMiddleBtn{
    box-sizing: border-box;
    padding: 7px 15px;
    text-align: center;
    font-size: 20px;
    color: #888888;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    flex-grow: 0;
    flex-shrink: 0;
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 75px;
    line-height: 20px;
}

.whiteMiddleBtn:hover{
    cursor: pointer;

    box-sizing: border-box;
    padding: 7px 15px;
    text-align: center;
    font-size: 20px;
    color: #888888;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    flex-grow: 0;
    flex-shrink: 0;
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 75px;
    line-height: 20px;
}

.whiteMiddleBtn_disable{
    cursor: wait;
    box-sizing: border-box;
    padding: 7px 15px;
    text-align: center;
    font-size: 20px;
    color: #adabab;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    flex-grow: 0;
    flex-shrink: 0;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 75px;
    line-height: 20px;
}


.redBigBtn{
    width: fit-content;
    height: fit-content;
    box-sizing: border-box;
    padding: 10px 15px;
    text-align: center;
    font-size: 20px;
    color: #ffffff;
    background-color: #F13B3B;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    flex-grow: 0;
    flex-shrink: 0;
    border-radius: 73px;
    line-height: 20px;
}

.redBigBtn:hover{
    width: fit-content;
    height: fit-content;

    cursor: pointer;

    box-sizing: border-box;
    padding: 10px 15px;
    text-align: center;
    font-size: 20px;
    color: #ffffff;
    background-color: #F13B3B;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    flex-grow: 0;
    flex-shrink: 0;
    border-radius: 73px;
    line-height: 20px;
}

.redBigBtn_disable{
    cursor: wait;
    box-sizing: border-box;
    padding: 10px 15px;
    text-align: center;
    font-size: 20px;
    color: #ffffff;
    background-color: #a4a4a4;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    flex-grow: 0;
    flex-shrink: 0;
    border-radius: 73px;
    line-height: 20px;
}





.whiteBigBtn{
    box-sizing: border-box;
    padding: 10px 15px;
    text-align: center;
    font-size: 20px;
    color: #a4a4a4;
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    flex-grow: 0;
    flex-shrink: 0;
    border-radius: 73px;
    line-height: 20px;
    border: 1px solid rgba(0, 0, 0, 0.1);
}

.whiteBigBtn:hover{
    cursor: pointer;

    box-sizing: border-box;
    padding: 10px 15px;
    text-align: center;
    font-size: 20px;
    color: #a4a4a4;
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    flex-grow: 0;
    flex-shrink: 0;
    border-radius: 73px;
    line-height: 20px;
    border: 1px solid rgba(0, 0, 0, 0.1);
}

.whiteBigBtn_disable{
    cursor: wait;
    box-sizing: border-box;
    padding: 10px 15px;
    text-align: center;
    font-size: 20px;
    color: #a4a4a4;
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    flex-grow: 0;
    flex-shrink: 0;
    border-radius: 73px;
    line-height: 20px;
    border: 1px solid rgba(0, 0, 0, 0.1);
}
