.outView{
    cursor: pointer;
    width: 100%;
    max-height: 300px;
    display: grid;
    grid-template-columns: 500px auto;
    padding: 10px;
}

.img{
    border-radius: 4px;
    object-fit: cover;
    width: 100%;
    overflow: hidden;
    max-height: 300px;
}

.titleGroup{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}
.title{
    box-sizing: border-box;
    padding: 20px;

    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    /* identical to box height, or 133% */


    color: #333333;

}
.price{
    box-sizing: border-box;
    padding: 20px;

    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 40px;
    /* identical to box height, or 143% */


    color: #FC3349;
}
