.outView{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.contentView{
    width: 1200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.userInfo{
    width: 100%;
}
.contentArea{
    width: 100%;
}

.personInfoView{
    width: 100%;
    display: grid;
    grid-template-columns: 150px auto 150px;
    height: 100px;
    padding: 10px;
    box-sizing: border-box;
    margin: 40px 0;

    background: #F8F8F8;
    border-radius: 10px;
}

.personInfoView .profileArea{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding-left: 30px;
}

.personInfoView .profileImg{
    width: 70px;
    height: 70px;
    object-fit: cover;
    border-radius: 50%;
}

.personInfoView .nickNameArea{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
}

.personInfoView .personSetting{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.logoutBtn{
    cursor: pointer;
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: justify;
    color: #c5c3c3;
}

.logoutBtn:hover{
    cursor: pointer;
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: justify;
    color: #606260;
}
