.outView {
    width: 100vw;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
}

.contentView {
    width: 1200px;
    box-sizing: border-box;
}

.contentViewFirstPart{
    width: 100%;
    display: grid;
    grid-template-columns: 681px auto 209px;
    grid-gap: 8px;
    padding: 20px 0;
    height: 383px;
}

.contentViewSecondPart{
    min-height: 800px;
    width: 100%;
}
