.outView {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
}

.contentView {
    width: 1200px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.coverInfoArea {
    width: 100%;
    display: grid;
    grid-template-columns: 760px auto;
    max-height: 468px;
    margin: 20px 0;
}

.coverImage {
    width: 100%;
    max-height: 468px;
    overflow: hidden;
    object-fit: cover;
}

.coverTextArea {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    box-sizing: border-box;
    padding: 10px;
}

.coverAreaTitle {
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    /* or 140% */

    text-align: justify;

    color: #111111;

}

.coverAreaPrice {
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 39px;

    color: #FC3349;
    padding: 30px 0;
}

.coverAreaBusinessInfo {
    width: 100%;
    flex-grow: 1;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.coverAreaBusinessInfoContent {
    width: 100%;
    height: 50%;

    background: #FFFFFF;
    box-shadow: 0px 0px 20px 4px rgba(0, 0, 0, 0.05);

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.businessProfileView{
    width: 64px;
    height: 64px;
    overflow: hidden;
    object-fit: cover;
}

.coverAreaBusinessContentFirst{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 5px;
}

.coverAreaBusinessContentFirstRight{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 6px;
}

.coverCompanyMobileGroup{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 10px 0;
}

.btnGroup{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 100%;
}

.coverAreaBusinessTitleText{
    cursor: pointer;
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    text-align: justify;
    color: #111111;
}

.caseDetailGroup{
    width: 760px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.caseDetailGroupTitle{
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    text-align: justify;
    color: #333333;
    padding: 15px 0;
}

.line{
    height: 1px;
    width: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    margin-bottom: 10px;
}

.dialogView{
    width: 300px;
    height: 200px;
}
.dialogContent{
    width: 300px;
    height: 200px;
}
