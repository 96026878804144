
.loginLeftBar {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    box-sizing: border-box;
    padding: 3% 3%;
}

.descText{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: left;

    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 30px;
    color: #FFFFFF;
}

.bottomText{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 14px;
    /* identical to box height */
    color: #FFFFFF;
    padding: 10px;
}
