.outView{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
}

.inputItem{
    box-sizing: border-box;
    margin: 0;
    outline: none;
    background: #F8F8F8;
    border: 1px solid rgba(219, 219, 219, 0.5);
    border-radius: 4px;
    font-size: 20px;
    padding: 4px;
}

.searchBtn{
    background: #F13B3B;
    border-radius: 4px;
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: #FFFFFF;
    margin-left: -2px;
    padding: 4px;
    width: 40px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.searchBtn:hover{
    cursor: pointer;

    background: #F13B3B;
    border-radius: 4px;
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: #FFFFFF;
    margin-left: -2px;
    padding: 4px;
    width: 40px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
