.outView {
    width: 100vw;
    background-color: #fff !important;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
}

.contentView {
    width: 1200px;
    display: grid;
    grid-template-columns: fit-content(200px) auto 100px 100px;
    box-sizing: border-box;
    padding: 8px;
    grid-gap: 10px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}
