
.content {
    width: 1200px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.businessInfoGroup {
    width: 100%;
    height: 150px;
    padding: 20px 0;

    display: grid;
    grid-template-columns: 150px auto 150px;
}

.coverCompanyMobileGroup {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 10px 0;
}

.caseItemList {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.caseItemTipText {
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    text-align: justify;
    color: #333333;
    padding: 20px 0;
}

.caseItemListData {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
}

.line {
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.08);
}

.itemWrap {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.businessInfoView{
    box-sizing: border-box;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}
