.outView {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.tipText {
    cursor: pointer;
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #666666;
}

.userInfoGroup {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.userInfoNickName {
    padding: 0 3px;
}

.userInfoProfile {
    border-radius: 50%;
    width: 30px;
    height: 30px;
}
