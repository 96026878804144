.uploadFileView {
    padding: 10px;
}

.outView {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.imgView{
    width: 100px;
    height: 100px;
    overflow: hidden;
    object-fit: fill;
    padding: 4px;
    margin: 5px;
    box-sizing: border-box;
}
.processView{
    width: 100px;
    height: 100px;
    padding: 4px;
    margin: 5px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.imgGroup{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.uploadImgGroupView{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
}
